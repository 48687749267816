import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Home() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <div className="bg-[#E0E8D9] pb-[94px]">
        <h1
          data-aos="fade-up"
          style={{ fontFamily: "HoneyBear" }}
          className="text-[80px] pb-[58px] pt-[22px] text-center uppercase m-0 font-[400] "
        >
          Babe
        </h1>
        <div className="flex justify-center flex-col items-center">
          <img src="assets/logo.svg" data-aos="fade-right" alt="" />
          <h6
            data-aos="fade-up"
            style={{ fontFamily: "Komit" }}
            className="text-[56px] max-w-[420px] pb-[98px] m-auto w-full leading-[50px] !font-[400] uppercase text-center"
          >
            a hot degenerate gambler cat in the web3 space!
          </h6>
          <button
            style={{ fontFamily: "Komit" }}
            className="font-[400] rotate-[18deg] text-[26px] py-[6px] px-[20px] uppercase border-[4px] border-[#000000] bg-[#6C4210] text-[#FFFFFF]"
          >
            BUY $BABE
          </button>
        </div>
      </div>
      <div className="line-loop-container">
        <div className="line-loop flex items-center gap-[50px]">
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $BABE
          </h6>
        </div>
      </div>
      <div className="bg-[#6C4210] pt-[112px]">
        <h1
          data-aos="fade-right"
          style={{ fontFamily: "Komit" }}
          className="text-[56px] pb-[33px] text-center uppercase m-0 text-[#FFFFFF]"
        >
          HOW TO BUY
        </h1>
        <h6
          data-aos="fade-left"
          style={{ fontFamily: "Komit" }}
          className="text-[36px] text-[#FFFFFF] max-w-[538px] w-full m-auto pb-[96px] uppercase  text-center"
        >
          Follow these easy steps to become a Babe holder
        </h6>
        <div className="max-w-[1500px] m-auto px-[30px] w-full">
          <div className="grid sm:grid-cols-4 grid-cols-1 gap-[20px] pb-[100px]">
            <div className="bg-[#FFFFFF] px-[26px] py-[36px] overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[18px] uppercase m-0 font-[800] text-[#000000] pb-[16px] leading-[30px]"
              >
                Create a wallet on phantom:
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[31px] leading-[36px] text-[#000000]"
              >
                Sign up on exchanges like Binance, KuCoin, or Uphold to purchase
                SOL. Transfer SOL to your Phantom Wallet deposit address.
              </p>
            </div>
            <div className="bg-[#FFFFFF] px-[26px] py-[36px] overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[18px] uppercase m-0 font-[800] text-[#000000] pb-[16px] leading-[30px]"
              >
                Connect Phantom Wallet to Raydium:
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[31px] leading-[36px] text-[#000000]"
              >
                Visit the Raydium website, click "Connect Wallet" at the top
                right, and select Phantom. Enter your password to connect.
              </p>
            </div>
            <div className="bg-[#FFFFFF] px-[26px] py-[36px] overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[18px] uppercase m-0 font-[800] text-[#000000] pb-[16px] leading-[30px]"
              >
                Swap SOL for $BABE:
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[31px] leading-[36px] text-[#000000]"
              >
                On Raydium, go to the "Swap" tab. Select SOL as the input and
                $BABE as the output. Enter the amount and confirm the swap.
              </p>
            </div>
            <div className="bg-[#FFFFFF] px-[26px] py-[36px] overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[18px] uppercase m-0 font-[800] text-[#000000] pb-[16px] leading-[30px]"
              >
                Verify Contract Address:
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[31px] leading-[36px] text-[#000000]"
              >
                Ensure you double-check the contract address and Raydium link
                before connecting your wallet to avoid scams.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#e0e8d9] overflow-hidden">
        <h1
          data-aos="fade-down"
          style={{ fontFamily: "Komit" }}
          className="text-[64px] pt-[141px] text-center uppercase m-0 text-[#6C4210] pb-[6px]"
        >
          SOCIALS
        </h1>
        <h6
          data-aos="fade-up"
          style={{ fontFamily: "Komit" }}
          className="text-[36px] text-[#6C4210] max-w-[800px] w-full m-auto pb-[80px] uppercase text-center"
        >
          Join the $BABE community
        </h6>
        <ul className="flex justify-center items-center gap-[40px]">
          <Link
            className="h-[100px] rotate-[20.77deg] w-[100px]"
            target="_blank"
            to="https://x.com/babecatonsol"
            rel="noopener noreferrer"
          >
            <img
              src="assets/item1.webp"
              alt=""
              className="h-[100px] w-[100px] bg-[#000000]"
            />
          </Link>
          <Link
            className="h-[100px] rotate-[-8.3deg] w-[100px]"
            target="_blank"
            to="https://t.me/babethecat"
            rel="noopener noreferrer"
          >
            <img
              src="assets/item2.webp"
              alt=""
              className="h-[100px] w-[100px] bg-[#000000]"
            />
          </Link>
          {/* <Link
            className="h-[100px] rotate-[16.58deg] w-[100px]"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/item3.svg"
              alt=""
              className="h-[100px] p-[14px] w-[100px] bg-[#000000]"
            />
          </Link> */}
        </ul>
        <div data-aos="fade-up" className="flex justify-center pt-[78px]">
          <img src="assets/photo1.svg" alt="" />
        </div>
      </div>
    </>
  );
}

export default Home;
